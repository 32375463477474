import Cookies from "js-cookie";
import UAParser from "ua-parser-js";

const MI = {};
const conversionEndpoint = new URL(__CONVERSION_URL__);

let _debug = false;
let _searchParams = {};
let _globalVars = {};
let _miid = "";
let _previousPageUrl = window.location.href;

const _processMIID = () => {
  _searchParams = new URLSearchParams(window.location.search);
  _miid = _searchParams.get("_miid");

  if (!_miid) {
    _miid = _searchParams.get("makeinfluence_id");
    _searchParams.delete("makeinfluence_id");
  }

  if (!_miid) {
    _miid = Cookies.get("_miid");
  }

  // // for older cookies delete after feb 1st 2022
  // if (!_miid) {
  //     _miid = Cookies.get('_mid');
  //     Cookies.remove('_mid');
  // }

  if (_miid) {
    Cookies.set("_miid", _miid, { expires: __CLIENT_COOKIE_EXPIRY_DAYS__ });
  }
};

const _getVar = (varible) => {
  return typeof _globalVars[varible] !== "undefined"
    ? _globalVars[varible]
    : null;
};

MI.set = (varible, value) => {
  _globalVars[varible] = value;
};

MI.debug = (el) => {
  _debug = document.getElementById(el);
};

MI.send = (...args) => {
  if (!args.length || typeof args[0] !== "string") {
    return;
  }

  if (!_miid) {
    _processMIID();
  }

  switch (args[0].toLowerCase()) {
    case "pageview":
      MI.pageView();
      break;
    case "conversion":
      MI.conversion(args[1]);
  }
};

MI.getMiid = () => {
  if (!_miid) {
    _processMIID();
  }
  return _miid;
};

MI.pageView = () => {
  const uaParser = new UAParser();

  // to support calls directly to MI.pageView (rather than the new MI.send('pageview'))
  if (!_miid) {
    _processMIID();
  }

  if (_debug) {
    _debug.innerHTML = JSON.stringify(uaParser.getResult(), null, 2);
    _debug.style.display = "block";
  }

  // Stop pageView execution if no _miid
  if (!_miid) {
    return;
  }

  const updateParams = () => {
    if (!_searchParams.get("_miid") || _searchParams.get("_miid") === "") {
      _searchParams.set("_miid", _miid);
      window.history.replaceState(
        "",
        window.document.title,
        `${location.pathname}?${_searchParams}`
      );
    }
  };

  if (["mobile", "tablet"].includes(uaParser.getDevice().type)) {
    window.addEventListener("click", () => {
      if (window.location.href !== _previousPageUrl) {
        _previousPageUrl = window.location.href;
        updateParams();
      }
    });
    updateParams();
  }
};

MI.conversion = async (params = {}) => {
  const bid = _getVar("business_id");

  if (params.promotion_codes && Array.isArray(params.promotion_codes)) {
    params.promotion_codes = params.promotion_codes.join();
  }

  if (params.promotion_codes && typeof params.promotion_codes !== "string") {
    console.warn(
      "MI.conversion: string or array are the only valid types for 'promotion_codes'. Will not be added to conversion."
    );
    delete params.promotion_codes;
  }

  if (params.promotion_codes) {
    params.promotion_code = params.promotion_codes;
    delete params.promotion_codes;
  }

  if (params.unique_id) {
    params.uid = params.unique_id;
    delete params.unique_id;
  }

  params.image_response = "false";

  if (_miid === undefined) {
    _miid = null;
  }

  conversionEndpoint.search = new URLSearchParams({ bid, ...params, _miid });
  const conversionResponse = await fetch(conversionEndpoint);

  if (_debug && conversionResponse.status === 200) {
    _debug.innerHTML = JSON.stringify(await conversionResponse.json(), null, 2);
    _debug.style.display = "block";
  }
};

export default MI;
