
import MI from './MI';

// process any queued MI calls and set global MI object
((w, cmd, obj) => {
    obj = w['MakeInfluenceObject'];
    if (w[obj] && w[obj].q) {
        do {
            cmd = w[obj].q.shift();
            MI[cmd.n](...cmd.a);
        } while (w[obj].q.length > 0);
    }
    w[obj] = MI;
})(window);